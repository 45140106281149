import { Router } from 'next/router';
import { ISupplier_Type } from '@bridebook/models/source/models/Suppliers.types';
import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { env } from 'lib/env';
import { InboxPanel } from 'lib/inbox-panel/inbox-panel';
import { removeForwardSlash } from 'lib/utils/strings';
import { SettingsSection, TShortlistTab } from './types';

/**
 * An object with URLs to protected pages (only accessible for logged-in users).
 * It's a part of the UrlHelper object as well as a reference for _middleware redirecting.
 */
export const ProtectedUrls = {
  achievements: '/achievements',
  bookmarks: '/bookmarks',
  budget: {
    main: '/budget',
    drawer: (name: string, id: string) => `/budget/${name}-${id}`,
  },
  checklist: {
    main: '/checklist',
    task: (name: string, id: string) => `/checklist/${name}-${id}`,
  },
  guestlist: '/guestlist',
  home: '/home',
  inbox: {
    conversation: (convId: string) => `/inbox/${convId}`,
    main: '/inbox',
  },
  onboarding: '/onboarding',
  collaboratorOnboarding: '/collaborator-onboarding',
  planning: '/planning',
  recommendations: '/recommendations',
  scrapbook: '/scrapbook',
  savedInspiration: '/scrapbook/inspiration',
  inspiration: '/inspiration',
  settings: {
    iconSwitch: '/settings/account-details#icon-switch',
    inviteCollaborator: '/settings/invite-collaborator',
    main: '/settings',
    manageCollaborators: '/settings/manage-collaborators',
    tab: (tab: SettingsSection) => ({
      href: `/settings?tab=${tab}`,
      as: `/settings/${tab}`,
    }),
  },
  suppliers: {
    main: '/suppliers',
    supplier: (slug: string, supplierQueryId: string) => `/wedding-${slug}/${supplierQueryId}`,
  },
  shortlist: {
    tab: (tab: TShortlistTab) => ({
      href: `/shortlist?tab=${tab}`,
      as: `/shortlist/${tab}`,
    }),
    tabBySupplierType: (supplierType?: Slug) => {
      const tab = !supplierType || supplierType === 'venue' ? 'venues' : 'suppliers';
      return {
        href: `/shortlist?tab=${tab}`,
        as: `/shortlist/${tab}`,
      };
    },
    main: '/shortlist',
    supplierDetails: (id: string, supplierType: Slug, shortlistTab?: TShortlistTab) => {
      const tab = shortlistTab || (supplierType === 'venue' ? 'venues' : 'suppliers');
      return {
        href: `/shortlist?category=${tab}&supplierId=${id}`,
        as: `/shortlist/${tab}/${id}`,
      };
    },
    // For now we can only compare venues
    comparison: '/shortlist/venues/comparison',
  },
  specialOffers: '/special-offers',
  supplier: '/supplier',
  quizzes: '/quizzes',
  weddingFairs: '/wedding-fairs',
  globalLocations: '/global-locations',
  swiping: {
    category: (slug: UrlFriendlySlug) => `/swiping/${slug}`,
    main: '/swiping',
  },
};

/**
 * An object with URLs to pages available only to logged-out
 */
export const GuestsOnlyUrls = {
  changePassword: '/change-password',
  login: '/signup?mode=login',
  signup: '/signup',
  collaboration: '/accept-collaboration-invite',
  signupCordova: '/signup?welcome=show',
};

/**
 * An object with URLs to pages accessible for both logged in/out user).
 */
export const PublicUrls = {
  acceptCollaborationInvite: '/accept-collaboration-invite',
  article: {
    id: (articleId: string) => '/article/' + articleId,
    main: '/article',
  },
  inboxPanel: (panelType?: InboxPanel, supplierType?: ISupplier_Type) =>
    `/inbox-panel/${panelType ?? ''}/${supplierType ?? ''}`.replace(/[/]+$/, ''),
  author: {
    id: (authorId: number) => '/author-page/' + authorId,
    main: '/author-page',
  },
  index: '/',
  landing: '/landing',
  reviews: '/reviews',
  search: '/search',
  /**
   * @deprecated - search landing page was removed
   */
  searchLanding: '/search',
  weddingIdeasInspiration: '/wedding-ideas-inspiration',
  weddingPlanningTools: {
    main: '/wedding-planning-tools',
    guestlist: '/wedding-planning-tools/guestlist',
    shortlist: '/wedding-planning-tools/supplier-shortlist',
    budget: '/wedding-planning-tools/budget',
    checklist: '/wedding-planning-tools/checklist',
  },
  weddingGiftLists: '/wedding-gift-lists',
  bridesmaidDresses: '/bridesmaid-dresses',
  weddingSuppliers: '/wedding-suppliers',
  advice: '/advice',
  terms: '/terms',
  privacyPolicy: '/privacy-policy',
  cookiePolicy: '/cookie-policy',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  pressAndMedia: '/pressandmedia',
  appSupport: '/app-support',
  error: '/404',
  // This path is used in firebase authentication email templates.
  // Do not remove it unless it's changed in firestore.
  accountActionHandler: '/account-action-handler',
};

/**
 * Manages all the URLs in one place.
 * Add a simple string or an object with "href" and "as" for URLs with params.
 */
export const UrlHelper = {
  ...PublicUrls,
  ...GuestsOnlyUrls,
  ...ProtectedUrls,
};

const PathsWithHiddenMobileNav = {
  supplierProfile: '/wedding-',
  articles: '/article',
  onboarding: '/onboarding',
  collaboratorOnboarding: '/collaborator-onboarding',
  signup: '/signup',
  swiping: UrlHelper.swiping.main,
};

/* ############################################################################
 *  UTILS
 * ######################################################################### */

// These pages are available only for logged-in users
export const loggedInOnlyPages = Object.values(ProtectedUrls)
  .map((url) => (typeof url === 'string' ? url : url.main))
  .map(removeForwardSlash);

export const getIsMobileNavShown = (pathname: string, marketPrefix: string) =>
  !Object.values(PathsWithHiddenMobileNav).some((path) =>
    pathname.startsWith(`/${marketPrefix}${path}`),
  );

// These pages are available only for logged-out guests
export const loggedOutOnlyPages = Object.values(GuestsOnlyUrls).map(removeForwardSlash);

// Returns true if a given path is included in ProtectedUrls
export const getIsProtectedPage = (path: Router['pathname']) => {
  const cleanPath = removeForwardSlash(path.replace('/[market]', ''));
  return loggedInOnlyPages.some((url) => cleanPath.startsWith(url));
};

export const getContactFormHelpUrl = (countryCode: CountryCodes) => {
  switch (countryCode) {
    case CountryCodes.DE:
      return 'https://share.hsforms.com/1CUvOH5GOTFyK0XdC_wbDAQ1p8l9';
    case CountryCodes.FR:
      return 'https://share.hsforms.com/1LugYkDu7RJy_wXxCUAE3Tw1p8l9';
    default:
      return 'https://share.hsforms.com/1SmbWSJYtSOGpM-mC5KZaZQ1p8l9';
  }
};

export const getKnowledgeBaseUrl = (countryCode: CountryCodes) => {
  switch (countryCode) {
    case CountryCodes.DE:
      return 'https://support.bridebook.com/de/support';
    case CountryCodes.FR:
      return 'https://support.bridebook.com/fr/support';
    default:
      return 'https://support.bridebook.com/en/support';
  }
};

export const getCareersUrl = (market: Market, ref: string) => {
  const path = (() => {
    switch (market.country) {
      case CountryCodes.DE:
        return `/${market.prefix}/karriere`;
      case CountryCodes.FR:
        return `/${market.prefix}/carriere`;
      default:
        return `/${gazetteer.getMarketByCountry(CountryCodes.GB).prefix}/careers`;
    }
  })();

  return env.CMS_LANDING_URL + path + `?ref=${ref}`;
};
